interface Offset {
    top: number;
    bottom: number;
}

export default function getFontOffset({
    ascender,
    descender,
    lineHeight,
    capHeight,
    unitsPerEm,
}: {
    ascender: number;
    descender: number;
    lineHeight: number;
    capHeight: number;
    unitsPerEm: number;
}): Offset {
    const overflow = ascender + Math.abs(descender) - unitsPerEm;
    const halfOverflow = overflow / 2;
    const lineHeightAdjustment = (lineHeight - 1) / 2;
    const bottom = halfOverflow / unitsPerEm;
    const top = (ascender - capHeight - halfOverflow) / unitsPerEm;

    return {
        top: -(top + lineHeightAdjustment),
        bottom: -(bottom + lineHeightAdjustment),
    };
}

export const getFontOffsetCircularBook = ({
    lineHeight,
}: {
    lineHeight: number;
}): Offset =>
    getFontOffset({
        ascender: 997,
        descender: 268,
        capHeight: 709,
        unitsPerEm: 1000,
        lineHeight,
    });
