import React from 'react';
import RichContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import notNull from '../utils/notNull';
import { sanity } from '../../config.json';
import { Viewport } from '../Settings';
const { projectId, dataset } = sanity.client;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(24, 1fr);

    @media (max-width: ${Viewport.TabletPortrait}px) {
        grid-template-columns: 100%;
        padding: 0 var(--sizeGutters);
    }
`;

const Inner = styled(RichContent)`
    grid-column: 4 / span 14;
    text-align: left;
    color: var(--foregroundColor);
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--size5);
    font-size: var(--size5);
    line-height: 1.38;

    ul {
        list-style-type: disc;
        padding-left: var(--size4);

        @media (max-width: ${Viewport.Mobile}px) {
            padding-left: 0;
            list-style-position: inside;
        }
    }

    li + li {
        margin-top: 0.5em;
    }

    ol {
        list-style-type: disc;
        padding-left: var(--size4);
    }

    h2 {
        font-size: var(--size6);
        line-height: var(--lineHeightMed);

        @media (max-width: ${Viewport.Mobile}px) {
            font-size: var(--size3);
            font-weight: bold;
        }
    }

    @media (max-width: ${Viewport.TabletPortrait}px) {
        grid-column: 1 / span 1;
        font-size: var(--size4);
    }

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size3);
    }
`;

function Intro({ contentRaw }: { contentRaw: any }): React.ReactElement | null {
    return contentRaw ? (
        <Container>
            <Inner
                blocks={contentRaw.filter(notNull)}
                renderContainerOnSingleChild
                projectId={projectId}
                dataset={dataset}
            />
        </Container>
    ) : null;
}

export default React.memo(Intro);
