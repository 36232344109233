import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { mix } from 'polished';
import { Color } from '../Settings';

interface ThemeConfig {
    foregroundColor: Color;
    backgroundColor: Color;
    highlightColor: Color;
}

const createThemeCss = ({
    foregroundColor,
    backgroundColor,
    highlightColor,
}: ThemeConfig): FlattenSimpleInterpolation => {
    return css`
        overflow: hidden;
        --foregroundColor: ${foregroundColor};
        --foregroundColorMix1: ${mix(1 / 9, backgroundColor, foregroundColor)};
        --foregroundColorMix2: ${mix(2 / 9, backgroundColor, foregroundColor)};
        --foregroundColorMix3: ${mix(3 / 9, backgroundColor, foregroundColor)};
        --foregroundColorMix4: ${mix(4 / 9, backgroundColor, foregroundColor)};
        --foregroundColorMix5: ${mix(5 / 9, backgroundColor, foregroundColor)};
        --foregroundColorMix6: ${mix(6 / 9, backgroundColor, foregroundColor)};
        --foregroundColorMix7: ${mix(7 / 9, backgroundColor, foregroundColor)};
        --foregroundColorMix8: ${mix(8 / 9, backgroundColor, foregroundColor)};
        --backgroundColor: ${backgroundColor};
        background-color: var(--backgroundColor);
        --highlightColor: ${highlightColor};
    `;
};

export const LightTheme = styled.div`
    ${createThemeCss({
        foregroundColor: Color.Blue,
        backgroundColor: Color.White,
        highlightColor: Color.Jade,
    })};
`;

export const DarkTheme = styled.div`
    ${createThemeCss({
        foregroundColor: Color.White,
        backgroundColor: Color.Blue,
        highlightColor: Color.Jade,
    })};
`;

export const JadeTheme = styled.div`
    ${createThemeCss({
        foregroundColor: Color.White,
        backgroundColor: Color.Jade,
        highlightColor: Color.JadeWhite,
    })};
`;

export const MonoTheme = styled.div`
    ${createThemeCss({
        foregroundColor: Color.Black,
        backgroundColor: Color.White,
        highlightColor: Color.Gray,
    })};
`;
