import React from 'react';
import styled from 'styled-components';
import { Viewport, FACEBOOK_URL } from '../Settings';

const Container = styled.div`
    display: flex;
    align-items: flex-end;
`;

const Link = styled.a`
    letter-spacing: normal;
    text-align: left;
    writing-mode: vertical-rl;
    color: var(--highlightColor);
    font-size: var(--size4);
    transform: rotate(180deg);
    white-space: nowrap;
    text-decoration: none;

    & + & {
        margin-left: var(--size3);
    }

    @media (max-width: ${Viewport.TabletPortrait}px) {
        font-size: var(--size3);

        & + & {
            margin-left: var(--size2);
        }
    }
`;

function Socials(): React.ReactElement {
    return (
        <Container>
            <Link href={FACEBOOK_URL}>1. Facebook</Link>
        </Container>
    );
}

export default React.memo(Socials);
