import React from 'react';
import { Helmet } from 'react-helmet';
import { PageMetadataFragment } from '../__generated__/PageMetadataFragment';

export default function Metadata({
    pageMetadata,
}: {
    pageMetadata: PageMetadataFragment;
}): React.ReactElement {
    return (
        <Helmet>
            {pageMetadata.title && <title>{pageMetadata.title}</title>}
            {pageMetadata.description && (
                <meta name="description" content={pageMetadata.description} />
            )}
        </Helmet>
    );
}
