import React from 'react';
import styled from 'styled-components';
import { Link as _Link } from 'gatsby';
import { ReactComponent as LogoSvg } from '../assets/logo-rev.svg';
import { Viewport } from '../Settings';
import { getFontOffsetCircularBook } from '../utils/getFontOffset';

const Container = styled.div`
    padding: var(--size5) 0 var(--size2);
    display: flex;
    padding: var(--sizeGutters);
    justify-content: space-between;
`;

const Logo = styled.div`
    width: calc(100vw / 24 * 5);

    @media (max-width: ${Viewport.Mobile}px) {
        width: calc(100vw / 24 * 10);
    }
`;

const lineHeight = 1.5;
const offset = getFontOffsetCircularBook({ lineHeight });

const Links = styled.nav`
    grid-column: 20 / span 4;
    font-size: var(--size5);
    line-height: ${lineHeight};
    text-align: right;
    color: var(--foregroundColor);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: ${offset.top}em;

    @media (max-width: ${Viewport.TabletPortrait}px) {
        font-size: var(--size4);
    }

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size3);
    }
`;

const Link = styled(_Link).attrs({ activeClassName: 'active' })`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--foregroundColor);
    text-decoration: none;

    &.active {
        &:after {
            content: '';
            display: block;
            --thickness: 0.125em;
            height: var(--thickness);
            width: calc(var(--thickness) * 7);
            background-color: var(--highlightColor);
            margin-left: calc(var(--thickness) * 3);
        }
    }
`;

interface Item {
    title: string;
    to: string;
}

const items: Item[] = [
    {
        title: 'Home',
        to: '/',
    },
    {
        title: 'About us',
        to: '/about-us/',
    },
    {
        title: 'Resources',
        to: '/resources/',
    },
    {
        title: 'Forms',
        to: '/forms/',
    },
    {
        title: 'Contact',
        to: '/contact/',
    },
];

function Navbar(): React.ReactElement {
    return (
        <Container>
            <Logo>
                <LogoSvg />
            </Logo>
            <Links>
                {items.map(
                    ({ to, title }: Item): React.ReactElement => {
                        return (
                            <Link key={title} to={to}>
                                {title}
                            </Link>
                        );
                    }
                )}
            </Links>
        </Container>
    );
}

export default React.memo(Navbar);
