import React from 'react';
import styled, { css } from 'styled-components';
import _Button from './Button';
import {
    Viewport,
    FACEBOOK_URL,
    CONTACT_EMAIL,
    MAILCHIMP_NEWSLETTER_SIGNUP_URL,
    MAILCHIMP_NEWSLETTER_EMAIL_NAME,
    MAILCHIMP_NEWSLETTER_HONEYPOT_NAME,
} from '../Settings';
import cnzLogoSvg from '../assets/cnz-logo.svg';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(24, minmax(0, 1fr));
    grid-template-areas:
        '. a a a a a a . b b b b b b . c c c c c c c c .'
        '. d d d d . . . . . . . . . . . . . . . . . . .';
    padding: var(--size8) 0;
    grid-row-gap: var(--size7);

    @media (max-width: ${Viewport.Desktop}px) {
        grid-template-areas:
            '. a a a a a a . b b b b b b . c c c c c c c c .'
            '. d d d d d . . . . . . . . . . . . . . . . . .';
    }

    @media (max-width: ${Viewport.TabletLandscape}px) {
        padding: var(--sizeGutters);
        grid-template-areas: 'a' 'b' 'c' 'd';
        grid-template-columns: 100%;
        grid-row-gap: var(--size4);
    }
`;

const Column = styled.div`
    &:nth-child(1) {
        grid-area: a;
    }

    &:nth-child(2) {
        grid-area: b;
    }

    &:nth-child(3) {
        grid-area: c;
    }

    &:nth-child(4) {
        grid-area: d;
    }
`;

const headingStyles = css`
    font-size: var(--size4);
    font-weight: bold;
    line-height: var(--lineHeightMed);
    color: var(--foregroundColor);

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size3);
    }
`;

const Heading = styled.div`
    ${headingStyles};
`;

const Label = styled.label`
    ${headingStyles}
`;

const Links = styled.div`
    font-size: var(--size4);
    line-height: var(--lineHeightMax);
    color: var(--foregroundColor);

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size3);
    }
`;

const Link = styled.a`
    text-decoration: none;
    color: inherit;
`;

const Form = styled.form`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: var(--size3);

    @media not all and (min-width: ${Viewport.TabletPortrait}px) {
        grid-template-columns: 100%;
        grid-row-gap: var(--size4);
    }
`;

const InputWrapper = styled.div`
    //
`;

const Input = styled.input`
    padding: 0;
    width: 100%;
    font-size: var(--size4);
    font-family: inherit;
    line-height: var(--lineHeightMax);
    color: var(--foregroundColor);
    border: none;
    border-bottom: 4px solid var(--foregroundColor);
    background: none;

    &::placeholder {
        // Hide placeholder on large viewports
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
        @media not all and (max-width: ${Viewport.TabletPortrait}px) {
            visibility: hidden;
        }
    }

    @media (max-width: ${Viewport.TabletPortrait}px) {
        border-bottom: none;
    }

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size3);
    }
`;

const Button = styled(_Button)`
    font-size: var(--size4);

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size3);
    }
`;

const Img = styled.img`
    display: block;
    width: 100%;
`;

function Footer(): React.ReactElement {
    return (
        <Container>
            <Column>
                <Heading>Contact us</Heading>
                <Links>
                    <Link href={`mailto:${CONTACT_EMAIL}`}>
                        {CONTACT_EMAIL}
                    </Link>
                </Links>
            </Column>
            <Column>
                <Heading>Follow us</Heading>
                <Links>
                    <Link href={FACEBOOK_URL}>Facebook</Link>
                </Links>
            </Column>
            <Column>
                <Form
                    action={MAILCHIMP_NEWSLETTER_SIGNUP_URL}
                    method="POST"
                    target="_BLANK"
                >
                    <InputWrapper>
                        <Label as="label" htmlFor="subscribeEmailInput">
                            Subscribe
                        </Label>
                        <Input
                            id="subscribeEmailInput"
                            type="email"
                            name={MAILCHIMP_NEWSLETTER_EMAIL_NAME}
                            required
                            placeholder="your@email.address"
                        />
                    </InputWrapper>
                    <Button block type="submit">
                        Submit
                    </Button>
                    <div
                        style={{ position: 'absolute', left: '-5000px' }}
                        aria-hidden="true"
                    >
                        <input
                            type="text"
                            name={MAILCHIMP_NEWSLETTER_HONEYPOT_NAME}
                            tabIndex={-1}
                        />
                    </div>
                </Form>
            </Column>
            <Column>
                <Img src={cnzLogoSvg} alt="" />
            </Column>
        </Container>
    );
}

export default React.memo(Footer);
