import styled from 'styled-components';

interface Props {
    block?: boolean;
}

export default styled.button<Props>`
    width: ${({ block }: Props): string => (block ? '100%' : 'initial')};
    padding: var(--size2);
    border: 4px solid var(--foregroundColor);
    color: var(--foregroundColor);
    font-size: var(--size4);
    font-weight: bold;
    line-height: 1;
    text-align: center;
`;
