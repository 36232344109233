import React from 'react';
import styled from 'styled-components';
import Socials from '../components/Socials';
import { Viewport } from '../Settings';
import { getFontOffsetCircularBook } from '../utils/getFontOffset';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(24, 1fr);

    @media (max-width: ${Viewport.TabletPortrait}px) {
        margin-top: var(--size4);
    }

    @media (max-width: ${Viewport.Mobile}px) {
        grid-template-columns: 100%;
        padding: 0 var(--sizeGutters);
    }
`;

const Column = styled.div`
    &:first-child {
        grid-column: 2 / span 19;

        @media (max-width: ${Viewport.Mobile}px) {
            grid-column: 1 / span 1;
        }
    }

    &:last-child {
        display: flex;
        grid-column: 22 / span 2;
        align-items: flex-end;
        justify-content: flex-end;
        height: 100%;

        @media (max-width: ${Viewport.Mobile}px) {
            display: none;
        }
    }
`;

const lineHeight = 1;
const offset = getFontOffsetCircularBook({ lineHeight });

const Title = styled.h1`
    margin: ${offset.top}em 0 ${offset.bottom}em;
    font-size: var(--size10);
    color: var(--highlightColor);
    line-height: ${lineHeight};
    &::first-line {
        color: var(--foregroundColor);
    }

    @media (max-width: ${Viewport.TabletLandscape}px) {
        font-size: var(--size9);
    }

    @media (max-width: ${Viewport.TabletPortrait}px) {
        font-size: var(--size8);
    }

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size6);
    }
`;

interface BannerProps {
    headingLine1: string | null;
    headingLine2: string | null;
}

function Banner({
    headingLine1,
    headingLine2,
}: BannerProps): React.ReactElement {
    return (
        <Container>
            <Column>
                <Title>
                    {headingLine1}
                    <br />
                    {headingLine2}
                </Title>
            </Column>
            <Column>
                <Socials />
            </Column>
        </Container>
    );
}

export default React.memo(Banner);
